const chineseCharacter = /[\u4E00-\u9FA5]/;
const englishCharacter = /[a-zA-Z]/;

export function getUserGroup(list = []) {
  // const arr =  originArr.map(item => ({ name: item.name, data: item }));
  // function someSort(arr) {
  // 不存在汉字排序方法的时候
  if (!String.prototype.localeCompare) return [];

  const arr = [...list];

  const letters = "*abcdefghjklmnopqrstwxyz".split("");
  const zh = "阿八嚓哒妸发旮哈讥咔垃痳拏噢妑七呥撒它穵夕丫帀".split("");

  const segs = [];
  //如果是汉字或者英文开头，分离出来
  const other = { letter: "#", data: [] };
  for (let i = 0; i < arr.length; i++) {
    //判断是不是数字开头
    const name = arr[i].name || "";
    const first = name[0] || "";
    if (!chineseCharacter.test(first) && !englishCharacter.test(first)) {
      other.data.push(arr[i]);
      arr.splice(i, 1);
      i--;
    }
  }
  // 开始遍历分组
  letters.forEach(function(letter, i) {
    const curr = { letter: letter.toUpperCase(), data: [] };
    arr.forEach(function(item) {
      const name = item.name || "";
      let first = name[0] || "";
      // 如果是汉字
      if (chineseCharacter.test(first)) {
        if (
          (!zh[i - 1] || zh[i - 1].localeCompare(first, "zh") <= 0) &&
          first.localeCompare(zh[i], "zh") == -1
        ) {
          curr.data.push(item);
        }
      }
      // 如果是英文
      if (englishCharacter.test(first)) {
        //统一转换为小写
        first = first.toLowerCase();
        if (first === letter) {
          curr.data.push(item);
          // console.log(curr)
        }
      }
    });
    if (curr.data.length > 0) {
      segs.push(curr);
      curr.data.sort(function(a, b) {
        return a.name.localeCompare(b.name, "zh");
      });
    }
  });
  segs.push(other);
  return segs;
  // }
}
