<template>
  <div
    class="has-nav-bar view-user-address-books-item"
    :class="{ 'is-query': query }"
  >
    <!-- :title="data.name" -->
    <van-nav-bar
      title="通讯录"
      :border="false"
      fixed
      left-arrow
      @click-left="onClickLeft"
    />
    <p style="text-align: center; margin: 0 0 10px;">
      {{ deptTreeCurrentName || data.name }}({{ list.length }}人)
    </p>
    <van-search
      v-if="query"
      v-model="keywords"
      class="search-bar"
      placeholder="请输入人员名称关键词"
      @search="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div class="container">
      <div>
        <van-cell
          v-for="item in deptTreeCurrentData"
          :key="item.id"
          :title="item.label"
          is-link
          @click="deptClick(item)"
        />
      </div>
      <template v-if="query">
        <UserList v-if="list.length" :data="list" />
        <p
          v-if="!list.length"
          style="text-align: center; margin: 40px 0 0; font-size: 14px;"
        >
          暂无数据
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { NavBar, Cell, Tag, Tabs, Tab, List, Search } from "vant";
import { mapActions, mapState } from "vuex";
import { getSelectTreeDept } from "@/api/authorization";
import defaultAvatar from "../../assets/images/contractor-person.png";
import { fileUrl } from "@/utils/constant";
import { getTreeDataAllId } from "@/utils";
import UserList from "./user-book/UserList.vue";

export default {
  name: "UserAddressBookItem",
  components: {
    [NavBar.name]: NavBar,
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [List.name]: List,
    UserList
  },
  props: {},
  data() {
    return {
      orgCode: this.$route.params.id,
      query: this.$route.query.levelIds || "",
      keywords: "",
      data: {},
      list: [],
      deptTreeData: []
      // deptTreeCurrentData: []
    };
  },
  computed: {
    ...mapState({
      addressBooksMaps: state => state.authorization.addressBooksMaps
    }),
    deptTreeCurrentData() {
      const level = this.query ? this.query.split(",") : null;
      if (level) {
        let searchData = this.deptTreeData || [];
        let name = "";
        while (level.length > 0) {
          const curId = level.shift();
          const find = searchData.find(i => i.id === curId);
          name = find?.label || "";
          searchData = find?.children || [];
        }
        return searchData;
      }
      return this.deptTreeData;
    },
    deptTreeCurrentName() {
      const level = this.query ? this.query.split(",") : null;
      if (level) {
        let searchData = this.deptTreeData || [];
        let name = "";
        while (level.length > 0) {
          const curId = level.shift();
          const find = searchData.find(i => i.id === curId);
          name = find?.label || "";
          searchData = find?.children || [];
        }
        return name;
      }
      return "";
    }
  },
  watch: {
    addressBooksMaps(val) {
      this.initData();
    },
    "$route.query": {
      handler(val) {
        this.query = val.levelIds || "";
        this.setData();
      }
    }
  },
  created() {
    this.getSelectTreeDept();
  },
  mounted() {
    // this.initData();
  },
  beforeDestroy() {
    this.data = {};
  },
  methods: {
    ...mapActions(["getAddressBooks"]),
    onClickLeft() {
      this.$router.back();
    },
    setData() {
      this.data = this.addressBooksMaps[this.orgCode];
      const allId = getTreeDataAllId(this.deptTreeCurrentData);
      const level = this.query ? this.query.split(",") : null;
      if (level) {
        allId.unshift(level[level.length - 1]);
      }
      this.list = (this.data.children || []).filter(i => {
        return allId.includes(i.department) && i.name.includes(this.keywords);
      });
    },
    initData() {
      if (!this.addressBooksMaps[this.orgCode]) {
        this.getAddressBooks().then(res => {
          this.setData();
        });
      } else {
        this.setData();
      }
    },
    onSearch() {
      // if (this.keywords) {
      //   this.list = this.list.filter(item => {
      //     return item.name.includes(this.keywords);
      //   });
      //   return;
      // }
      this.setData();
    },
    async getSelectTreeDept() {
      this.deptTreeData = await getSelectTreeDept(this.orgCode);
      this.initData();
    },
    deptClick(data) {
      const levelIds = this.$route.query.levelIds
        ? `${this.$route.query.levelIds},${data.id}`
        : data.id;
      this.$router.push({
        path: this.$router.path,
        query: {
          levelIds
        }
      });
    },
    avatarSrcSwitch(fileId) {
      if (fileId) {
        return `${fileUrl}${fileId}`;
      }
      return defaultAvatar;
    }
  }
};
</script>
<style lang="scss">
.view-user-address-books-item {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #f0f0f0;
  &.is-query {
    .container {
      padding: 0;
    }
    .search-bar {
      padding: 16px;
      background: #fff;
      margin-bottom: 24px;
    }
  }
  &.has-nav-bar {
    // padding-top: 140px;
    padding-top: 70px;
  }
  .search-bar {
    // position: fixed;
    // top: 58px;
    // z-index: 1;
    padding: 16px 10px;
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  .container {
    overflow-y: auto;
    height: calc(100vh - 156px);
    padding: 0 10px;
    .contact-person-title {
      font-size: 14px;
      padding: 10px 0 2px;
    }
    .user-address-books-item-list {
      background-color: #fff;
      // overflow-y: auto;
      // height: 100%;
      // .van-cell {
      //   padding-top: 10px;
      //   padding-bottom: 10px;
      //   color: #3b4664;
      //   font-size: 15px;
      //   &:not(:last-child)::after {
      //     left: 16px;
      //     right: 16px;
      //   }
      //   .van-cell__left-icon {
      //     margin-right: 10px;
      //   }
      //   .van-cell__value {
      //     a {
      //       color: #aeb3c0;
      //     }
      //   }
      //   .van-cell__label {
      //     text-overflow: ellipsis;
      //     overflow: hidden;
      //     white-space: nowrap;
      //     width: 40%;
      //   }
      // }
      .person-item {
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        .head-portrait-box {
          // display: inline-block;
          height: 40px;
          width: 40px;
          img {
            display: block;
            width: 40px;
            height: 40px;
          }
        }
        .person-info-box {
          flex: 1;
          // display: inline-block;
          vertical-align: top;
          padding: 0 0 0 10px;
          font-size: 14px;
          line-height: 18px;
          .name-mobile {
            width: 100%;
            display: flex;
            justify-content: space-between;
            a {
              color: #aeb3c0;
              margin: 0 0 0 10px;
            }
          }
          .position {
            padding: 8px 0 0;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>
