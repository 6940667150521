<template>
  <div class="user-list">
    <van-index-bar highlight-color="#1678FF" :index-list="indexList">
      <template v-for="(item, index) in group">
        <van-index-anchor
          :key="item.letter"
          class="user-list-anchor"
          :index="item.letter"
          >{{ item.letter }}</van-index-anchor
        >

        <van-cell
          v-for="(cell, cellIndex) in item.data"
          :key="`key-${index}-${cellIndex}`"
          @click="onClick(cell)"
        >
          <div class="user-list-cell">
            <div class="user-list-avatar">
              <img :src="avatarSrcSwitch(item.avatar)" />
            </div>
            <div class="user-list-info">
              <div class="user-list-name">{{ cell.name }}</div>
              <div class="user-list-tip">
                <span>{{ cell.positionName || "--" }}</span>
                <span @click.stop>
                  <a :href="`tel:${cell.mobile}`" class="user-list-mobile">
                    <van-icon
                      class-prefix="iconfont"
                      class="iconfont-shouji1 mobile-icon"
                    />
                    {{ cell.mobile || "--" }}</a
                  >
                </span>
              </div>
            </div>
          </div>
        </van-cell>
      </template>
    </van-index-bar>
  </div>
</template>

<script>
import { IndexBar, IndexAnchor } from "vant";
import { getUserGroup } from "./utils";
import defaultAvatar from "./images/person.png";
import { fileUrl } from "@/utils/constant";
const indexList = "abcdefghjklmnopqrstwxyz".split("").map(i => i.toUpperCase());
export default {
  components: {
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    indexList() {
      return [...indexList, "#"];
    },
    group() {
      return getUserGroup(this.data);
    }
  },
  methods: {
    avatarSrcSwitch(fileId) {
      if (fileId) {
        return `${fileUrl}${fileId}`;
      }
      return defaultAvatar;
    },
    onClick(cell) {
      this.$router.push({ name: "userDetail", params: { id: cell.id } });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-list {
  &-anchor {
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 22px;
  }

  &-cell {
    display: flex;
    align-items: stretch;
  }

  &-avatar {
    height: 44px;
    width: 44px;
    margin-right: 16px;
    img {
      display: block;
      width: 44px;
      height: 44px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-name {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 16px;
  }

  &-tip,
  &-mobile {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2e2e4d;
    line-height: 12px;
  }

  &-mobile {
    margin-left: 25px;
  }

  .mobile-icon {
    color: #aeb3c0;
    font-size: 12px;
  }

  ::v-deep {
    .van-cell {
      padding: 16px;
    }

    .van-index-bar__sidebar {
      font-size: 10px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1678ff;
      line-height: 14px;
    }
  }
}
</style>
